import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import SleepDisordersInfo from "../../components/services/SleepDisordersInfo";

const SleepDisorders = () => {
  return (
    <Layout>
      <PageHeader text="Sleep Disorders" />
      <ServicesHeader>
        Insufficient sleep can take a toll on your health and overall quality of
        life. If you experience insomnia or other sleep disorders, the
        experienced team at Sunstate Medical Associates in Lake Mary, Florida,
        offers treatments that boost sleep quality and lower your risk of
        accidents, unpleasant side effects, and weight gain. Call Sunstate
        Medical Associates for an appointment today or book one online.
      </ServicesHeader>
      <SleepDisordersInfo />
      <Conditions />
    </Layout>
  );
};

export default SleepDisorders;
