import React from "react";
import RequestButton from "../RequestButton";

const SleepDisordersInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Sleep Disorders Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">What are sleep disorders?</p>
        <p className="text-md text-gray-600 mb-12">
          Sleep disorders are medical conditions that prevent you from getting a
          good night’s sleep. Insomnia is a common sleep disorder in which you
          might have problems falling or staying asleep. Chronic sleep
          deprivation can take a toll on your health, which is why seeking
          treatment for your condition is important.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the symptoms of insomnia?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Common signs and symptoms of insomnia include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Waking up during the night </li>
          <li>Difficulty falling or staying asleep</li>
          <li>Waking up too early</li>
          <li>Waking up tired</li>
          <li>Chronic drowsiness</li>
          <li>Daytime tiredness</li>
          <li>Depression or anxiety</li>
          <li>Irritability</li>
          <li>Problems paying a attention</li>
          <li>Ongoing worries about sleep</li>
          <li>Increases in accidents</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          At the first sign of insomnia, see the Sunstate Medical Associates
          team for an evaluation to reduce your risk of accidents and health
          problems.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the risk factors for insomnia?
        </p>
        <p className="text-md text-gray-600 mb-12">
          Some of the risk factors associated with insomnia include genetics,
          being a woman, and being older than age 60. Your risk of insomnia also
          increases with mental health disorders, stress, and an irregular sleep
          schedule. Chronic sleep deprivation can contribute to weight gain,
          obesity, and obesity-related diseases like high blood pressure, heart
          disease, and diabetes.
        </p>

        <p className="text-xl text-gray-800 mb-2">How is insomnia diagnosed?</p>
        <p className="text-md text-gray-600 mb-12">
          Your primary care doctor can diagnose insomnia after reviewing your
          symptoms, discussing your medical history, and completing a physical
          exam. They might recommend you undergo a sleep study, too.
        </p>

        <p className="text-xl text-gray-800 mb-2">How is insomnia treated?</p>
        <p className="text-md text-gray-600 mb-3">
          If you experience insomnia your Sunstate Medical Associates specialist
          might recommend one of the following treatments:
        </p>
        <p className="text-lg text-gray-700 mb-2">Lifestyle changes</p>
        <p className="text-md text-gray-600 mb-3">
          Lifestyle habits that increase your chance of a good night’s sleep
          include eating a healthy diet, setting a regular sleep schedule,
          getting regular exercise (don’t work out late at night), avoiding
          afternoon naps, limiting caffeine and alcohol, sleeping in a cool dark
          room, and not going to bed hungry or very full.
        </p>
        <p className="text-lg text-gray-700 mb-2">
          Stress reduction techniques
        </p>
        <p className="text-md text-gray-600 mb-3">
          If stress contributes to insomnia, try relaxation techniques. Examples
          include breathing exercises, yoga, therapeutic massage, and meditation
          to make getting and staying asleep easier.
        </p>
        <p className="text-lg text-gray-700 mb-2">Sleeping medications</p>
        <p className="text-md text-gray-600 mb-3">
          If conservative treatments haven’t worked, your provider can prescribe
          medications to help you get a better night’s sleep.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Don’t live with insomnia or other sleep disorders when simple
          treatments are within reach at Sunstate Medical Associates. Call the
          office today to speak with a friendly team member or book using the
          online scheduler.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default SleepDisordersInfo;
